import React from "react"
import { Layout, SEO } from "../components"
import { ReactComponent as Logo } from "../../static/logos/footer-logo.svg"
import CindyCohen from "../images/cindycohen.jpg"

export default function about() {
  return (
    <Layout>
      <SEO title="Nosotros" />
      <div className="about">
        <div className="about__wrapper">
          <figure className="about__logo">
            <Logo className="about__logo-img" />
          </figure>
          <div className="about__information">
            <p className="about__description">
              La esencia de L´Atelier es única y siempre con su toque de elegancia, nunca falta el sello de Cindy Cohen
              que se distingue por su sencillez, visión artística y sobre todo un toque muy especial, así es el
              showroom, un lugar único y acogedor.
            </p>
            <p className="about__description">
              L´Atelier Des Fleurs abre sus puertas al público en Plaza la Noria. Cada rincón, cada mueble y cada
              florero fue diseñado con la intención de abrir un showroom para crear arreglos florales muy artísticos,
              ofreciendo algo nuevo y diferente a sus clientes.
            </p>
            <p className="about__description">
              En un principio creció muchísimo en la parte de arreglos florales a domicilio, luego se convirtió en el
              showroom para eventos sociales y corporativos y hoy en día surgió L´Atelier Home Design, donde se crean
              proyectos completos que van desde la elección de mobiliario, telas, accesorios y por supuesto, no pueden
              faltar la bellas flores de L´Atelier Des Fleurs.
            </p>
            <figure className="about__owner">
              <img src={CindyCohen} alt="Cindy Cohen" className="about__owner-thumbnail" />
              <p className="about__description">Cindy Cohen</p>
            </figure>
          </div>
        </div>
      </div>
    </Layout>
  )
}
